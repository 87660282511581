
  export const style = {
    api: {
      apiProduction: "https://suvinil.prd.appnoz.com.br/",
      newApiProduction: "null",
      samlUrl: null,
      nameProfile: "Pintou Parceria Suvinil",
      hostHeader: "suvinil",
    },

    icons: {
      loginIcon: "https://timcoo-geral.s3.amazonaws.com/Logos/suvinil.png",
      menuIcon: localStorage.getItem("menuIcon") || "https://timcoo-geral.s3.amazonaws.com/Logos/secondary_logo_suvinil.png",
      browserLogo: "https://timcoo-geral.s3.amazonaws.com/Logos/browser_logo_suvinil.png",
    },

    shareAttributes: {
      shareImage: "https://timcoo-geral.s3.amazonaws.com/Logos/suvinil.png",
      shareTitle: "Noz - Plataforma de comunicação",
      shareDescription: "O NOZ é um aplicativo de comunicação personalizado, onde é possível criar conteúdos de acordo com a segmentação do seu público de maneira gamificada.",
    },

    names: {
      nameApp: localStorage.getItem("nameApp") || "Pintou Parceria Suvinil",
      nameScore: "Pontos",
      nameStore: "Prêmios",
      nameAcademy: localStorage.getItem("nameAcademy") || "Treinamentos",
      nameTeam: localStorage.getItem("nameTeam") || "Profissionais",
      nameCalendar: localStorage.getItem("nameCalendar") || "Eventos",
      nameTools: localStorage.getItem("nameTools") || "Ferramentas",
    },

    colors: {
      primaryColor: localStorage.getItem("primaryColor") || "#FD9435",
      secondaryColor: localStorage.getItem("secondaryColor") || "#EE7202",
      tertiaryColor: "",
    },

    variables: {
      gamificationEnabled: true,
      child_profiles: [],
      hasPrivacyPolicies: true,
      externalUse: false,
      customClient: true,
    },
  };
